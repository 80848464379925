import { backendSrv } from '../services/backend_srv';

function sendUnknownKeyMetric(key: string) {
  backendSrv.post('/api/frontend-metrics', {
    events: [
      {
        name: 'frontend_unknown_local_storage_key',
        value: 1,
      },
    ],
  });
}

const seenKeys = new Set<string>();
function handleUnknownKey(key: string) {
  if (!seenKeys.has(key)) {
    seenKeys.add(key);
    sendUnknownKeyMetric(key);
  }
}

function __reset() {
  seenKeys.clear();
}

export { __reset, handleUnknownKey };
