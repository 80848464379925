import { APPROVED_FUNCTIONAL_KEYS, APPROVED_FUNCTIONAL_WILDCARD_KEYS } from './constants';
import { Registry, ShortbreadInstance } from './types';

function buildRegistry() {
  const registry: Registry = {};
  for (const key of APPROVED_FUNCTIONAL_KEYS) {
    registry[key] = { category: 'functional' };
  }
  for (const wildcard of APPROVED_FUNCTIONAL_WILDCARD_KEYS) {
    registry[wildcard.key] = { category: 'functional' };
  }
  return registry;
}

let shortbread: ShortbreadInstance | undefined;

function buildShortbreadInstance(): ShortbreadInstance | undefined {
  if (shortbread) {
    return shortbread;
  }

  try {
    if (window.AWSCShortbread) {
      shortbread = window.AWSCShortbread({
        registry: buildRegistry(),
        domain: location.hostname,
        onConsentChanged(c) {
          if (c.functional === false) {
            localStorage.clear();
          }
        },
      });
    } else {
      throw new Error('window.AWSCShortbread is undefined');
    }
  } catch (error) {
    console.error('failed to initialize shortbread', error);
  }
  shortbread?.checkForCookieConsent && shortbread.checkForCookieConsent();
  return shortbread;
}

function __reset() {
  shortbread = undefined;
}

export { __reset, buildShortbreadInstance as init };
