import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { css } from '@emotion/css';
import { reportExperimentView } from '@grafana/runtime';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { UpgradeBox, UpgradeContent } from 'app/core/components/Upgrade/UpgradeBox';
import Page from 'app/core/components/Page/Page';
import { getNavModel } from 'app/core/selectors/navModel';
import { EnterpriseStoreState } from '../types';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

function mapStateToProps(state: EnterpriseStoreState) {
  return {
    navModel: getNavModel(state.navIndex, 'reports'),
  };
}

const connector = connect(mapStateToProps);
export type Props = ConnectedProps<typeof connector>;

const ReportsUpgradePage = ({ navModel }: Props) => {
  const styles = useStyles2(getStyles);

  useEffect(() => {
    reportExperimentView(buildExperimentID('reporting'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <div className={styles.box}>
        <UpgradeBox featureName={'reporting'} />
      </div>
      <Page.Contents className={styles.contents}>
        <UpgradeContent
          featureName={'reporting'}
          description={
            'Reporting allows you to automatically generate PDFs from any of your dashboards and have Grafana email them to interested parties on a schedule.'
          }
          listItems={[
            'Customize your exact layout and orientation',
            'Personalize with your unique branding',
            'Specify permissions for users across your company',
            'Choose to send a report at custom intervals',
          ]}
          featureUrl={'https://grafana.com/docs/grafana/latest/enterprise/reporting/'}
          image={'reporting-email.png'}
          caption={
            'Create reports to share your SLO performance, business metrics, cost and utilization metrics or anything else you can express in a Grafana dashboard.'
          }
        />
      </Page.Contents>
      {/*Push down the page footer*/}
      <div className={styles.spacer} />
    </Page>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    contents: css`
      &.page-body {
        flex: 0;
      }
    `,
    spacer: css`
      flex: 1;
    `,
    box: css`
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(0, 1)};
      }

      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(0, 2)};
      }
    `,
  };
};

export default connector(ReportsUpgradePage);
