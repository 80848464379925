import React, { FC } from 'react';
import { config } from '@grafana/runtime';
import { Icon, IconName } from '@grafana/ui';
import { init } from '../../shortbread/shortbread';

export interface FooterLink {
  text: string;
  id?: string;
  icon?: string;
  url?: string;
  target?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: 'Documentation',
      icon: 'document-info',
      url: 'https://grafana.com/docs/grafana/latest/?utm_source=grafana_footer',
      target: '_blank',
    },
    {
      text: 'Support',
      icon: 'question-circle',
      url: 'https://grafana.com/products/enterprise/?utm_source=grafana_footer',
      target: '_blank',
    },
    {
      text: 'Community',
      icon: 'comments-alt',
      url: 'https://community.grafana.com/?utm_source=grafana_footer',
      target: '_blank',
    },
  ];
};

// AMG_CODEBASE: modify interface to support onClick
interface AmgFooterLink extends FooterLink {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export let getVersionLinks = (): AmgFooterLink[] => {
  const { buildInfo, licenseInfo } = config;
  const links: AmgFooterLink[] = [];
  const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';
  const shortbread = init();

  // AMG_CODEBASE: use different text for the footer
  if (config.featureToggles.affirmativeCookieConsent && shortbread) {
    links.push({
      target: '_self',
      id: 'cookies',
      text: 'Cookies',
      onClick: (e) => {
        e.preventDefault();
        shortbread?.customizeCookies();
      },
      url: '#',
    });
  }
  links.push({
    target: '_blank',
    id: 'version',
    text: `Amazon Grafana${stateInfo}`,
    url: 'http://docs.aws.amazon.com/grafana/latest/userguide/what-is-Amazon-Managed-Service-Grafana.html',
  });

  if (!buildInfo.hideVersion) {
    links.push({
      target: '_blank',
      id: 'version',
      text: `v${buildInfo.version}`,
    });
  }

  return links;
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getVersionLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  const links = getFooterLinks().concat(getVersionLinks());

  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
          {links.map((link) => (
            <li key={link.text}>
              <FooterItem item={link} />
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';

function FooterItem({ item }: { item: AmgFooterLink }) {
  const content = item.url ? (
    <a href={item.url} target={item.target} rel="noopener noreferrer" id={item.id} onClick={item?.onClick}>
      {item.text}
    </a>
  ) : (
    item.text
  );

  return (
    <>
      {item.icon && <Icon name={item.icon as IconName} />} {content}
    </>
  );
}
