import React, { useEffect } from 'react';
import { reportExperimentView } from '@grafana/runtime';
import { useTheme2 } from '@grafana/ui';
import Page from 'app/core/components/Page/Page';
import { UpgradeBox, UpgradeContent } from 'app/core/components/Upgrade/UpgradeBox';
import { connector, Props } from './AccessControlDataSourcePermissions';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const UpgradePage = ({ navModel, loadDataSource, loadDataSourceMeta, resourceId }: Props) => {
  const theme = useTheme2();

  useEffect(() => {
    loadDataSource(resourceId as any).then(loadDataSourceMeta);
  }, [resourceId, loadDataSource, loadDataSourceMeta]);

  useEffect(() => {
    reportExperimentView(buildExperimentID('data-source-permissions'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox featureName={'data source permissions'} />
        <UpgradeContent
          featureName={'data source permissions'}
          description={
            'With data source permissions, you can protect sensitive data by limiting access to this data source to specific users, teams, and roles.'
          }
          listItems={[
            'Protect sensitive data, like security logs, production databases, and personally-identifiable information',
            'Clean up users’ experience by hiding data sources they don’t need to use',
            'Share Grafana access more freely, knowing that users will not unwittingly see sensitive data',
          ]}
          image={`ds-permissions-${theme.isLight ? 'light' : 'dark'}.png`}
          featureUrl={'https://grafana.com/docs/grafana/latest/enterprise/datasource_permissions'}
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(UpgradePage);
