import React, { useEffect } from 'react';
import { reportExperimentView } from '@grafana/runtime';
import { useTheme2 } from '@grafana/ui';
import Page from 'app/core/components/Page/Page';
import { UpgradeBox, UpgradeContent } from 'app/core/components/Upgrade/UpgradeBox';
import { connector, Props } from './DataSourceCache';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const DataSourceUpgradePage = ({ navModel, pageId, loadDataSource, loadDataSourceMeta }: Props) => {
  const theme = useTheme2();

  useEffect(() => {
    loadDataSource(pageId).then(loadDataSourceMeta);
  }, [pageId, loadDataSourceMeta, loadDataSource]);

  useEffect(() => {
    reportExperimentView(buildExperimentID('query-caching'), ExperimentGroup.Test, '');
  }, []);

  return (
    <Page navModel={navModel}>
      <Page.Contents>
        <UpgradeBox featureName={'query caching'} />
        <UpgradeContent
          listItems={[
            'Load dashboards in less than a second from the cache, even when they include big queries and lots of users are looking at once',
            'Save money and avoid rate limiting by reducing the number of API calls you make to data sources like Splunk, CloudWatch and Github',
            'Author dashboards more smoothly by caching the data used to construct queries, like fields in ServiceNow or metrics available from Datadog',
          ]}
          image={`query-caching-${theme.isLight ? 'light' : 'dark'}.png`}
          featureUrl={'https://grafana.com/docs/grafana/latest/enterprise/query-caching'}
          featureName={'query caching'}
          description={
            'With query caching, you can load dashboards faster and reduce costly queries to data sources by temporarily storing query results in memory, Redis, or Memcached.'
          }
        />
      </Page.Contents>
    </Page>
  );
};

export default connector(DataSourceUpgradePage);
