import { config } from '../config';

import { isApprovedKey } from './is-approved-key';
import { StorageMethod, StorageWithoutIndex, StoreValue } from './types';

function registerShortbreadLocalStorageProxy() {
  Object.defineProperty(window, 'localStorage', {
    configurable: true,
    enumerable: true,
    value: new Proxy(localStorage, {
      set: function (ls: Storage, prop: string, value: StoreValue) {
        if (isApprovedKey(prop)) {
          ls[prop] = value;
        }
        return true;
      },
      get: function trap(ls: Storage, prop: string) {
        const isLSProperty = isLocalStorageProperty(prop);

        // an approved key in this scope means it must be bolted on: just return it
        if (!isLSProperty && isApprovedKey(prop)) {
          return ls[prop];
        }
        // unapproved, not built in
        if (!isLSProperty) {
          return;
        }
        // built in, non function property (length): just return it
        if (!isLocalStorageMethod(prop)) {
          return ls[prop];
        }

        return function innerTrap(key: never, val: never): string | null | void {
          switch (prop) {
            case 'clear':
              return ls[prop].apply(ls);
            case 'getItem':
              if (isApprovedKey(key)) {
                return ls[prop].apply(ls, [key]);
              }
              return null;
            case 'key': {
              const storageKey = ls[prop].apply(ls, [key]);
              if (storageKey && isApprovedKey(storageKey)) {
                return storageKey;
              }
              return null;
            }
            case 'removeItem':
              return ls[prop].apply(ls, [key]);
            case 'setItem':
              if (isApprovedKey(key)) {
                return ls[prop].apply(ls, [key, val]);
              }
              return undefined;
          }
        };
      },
    }),
  });
}

function isLocalStorageMethod(key: unknown): key is StorageMethod {
  switch (key) {
    case 'getItem':
    case 'setItem':
    case 'removeItem':
    case 'clear':
    case 'key':
      return true;
  }
  return false;
}

function isLocalStorageProperty(key: unknown): key is keyof StorageWithoutIndex {
  switch (key) {
    case 'getItem':
    case 'setItem':
    case 'removeItem':
    case 'clear':
    case 'key':
    case 'length':
      return true;
  }
  return false;
}

if (config.featureToggles?.affirmativeCookieConsent) {
  registerShortbreadLocalStorageProxy();
}
