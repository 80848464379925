import { APPROVED_FUNCTIONAL_WILDCARD_KEYS } from './constants';
import { handleUnknownKey } from './handle-unknown-key';
import { init } from './shortbread';

function isApprovedKey(key: string): boolean {
  const shortbread = init();
  if (!shortbread) {
    // if / when essential usages of localStorage exist, we must have a fallback
    // in case shortbread is not available.
    return false;
  }
  try {
    for (const wildcard of APPROVED_FUNCTIONAL_WILDCARD_KEYS) {
      // test the `key` argument against the pattern
      if (wildcard.pattern.test(key)) {
        // check for consent using the registered/approved wildcard key
        return shortbread.hasConsent(wildcard.key);
      }
    }

    return shortbread.hasConsent(key);
  } catch (error) {
    handleUnknownKey(key);
    return false;
  }
}

export { isApprovedKey };
